import "./App.css";
import PurchaseContainer from "./index.jsx";
import UpdatePurchse from "./newIndex.jsx";
const os = require("os-browserify/browser");

function App() {
  return (
    <div className="App">
      {/* <PurchaseContainer /> */}
      <UpdatePurchse />
    </div>
  );
}

export default App;
