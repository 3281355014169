import axios from "axios";
import toast from "react-hot-toast";

let Promise = require("promise");

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const axiosInstance = axios.create({
  baseURL: "https://api.unlock.fit/", //production
  // baseURL: "https://dev-api.unlock.fit/", //dev
  // baseURL: "https://z5d9jm6x-4002.inc1.devtunnels.ms/", //local
});

export const displayRazorpay = async (
  userId,
  Products,
  Data,
  planId,
  dnaInclude,
  DnaProducts,
  Total,
  setSteps,
  setSuccess,
  Success,
  Steps,
  setPaymentMessage,
  setProgress,
  CouponCode
) => {
  // console.log(
  //   "total",
  //   total,
  //   "userId",
  //   userId,
  //   "formValues",
  //   formValues,
  //   "allpurchase",
  //   allpurchase,
  //   "familyArray",
  //   familyArray
  // );
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const tots = Total;

  if (tots === 0) {
    setProgress(true);
    let body = {
      plan_id: Products?.id,
      user_id: userId,
      plan_price: Products?.special_price,
      orderid: CouponCode ? CouponCode : "",
      duration_id: Products?.durations[0].id ? Products?.durations[0].id : "",
      coupon_id: CouponCode ? CouponCode : "",
      is_dna_pro_included: dnaInclude,
      ...(dnaInclude === "true" && {
        dna_pro_details: {
          plan_id: DnaProducts.id,
          plan_price: DnaProducts.special_price,
        },
      }),
    };
    console.log("Free plan body", body);

    try {
      const response = await axiosInstance.post(
        `website/v1/buySubscription`,
        body
      );
      const message = response.data.data[0].message;
      setSuccess({ ...Success, payment: true });
      setProgress(false);
      setSteps({
        ...Steps,
        details: "done",
        payment: "done",
        test: "inactive",
      });
      setPaymentMessage(message);
    } catch (err) {
      setProgress(false);
      const errors = err.response?.data;
      toast.error(errors.message);
    }
    return;
  }

  const options = {
    // key: process.env.REACT_APP_PUBLIC_RAZORPAY,
    key: "rzp_live_TpYaDdpuUOV5dp", //live
    // key: "rzp_test_7PIqewalPNv3tL", //test
    // key: "rzp_test_0q14YMbTB7KoVy", //test
    currency: "INR",
    amount: dnaInclude === "true" ? tots * 100 : Products?.special_price * 100,
    // amount: 100,
    handler: async (response) => {
      console.log(response);
      setProgress(true);
      // let body = {
      //   plan_id: formValues[0].plan_id,
      //   userId: userId,
      //   plan_price: formValues[0].price,
      //   orderId: response.razorpay_payment_id,
      //   allpurchase: allpurchase,
      //   family: familyArray,
      // };
      // if (formValues[0].duration_id) {
      //   body.duration_id = formValues[0].duration_id;
      // }
      let body = {
        plan_id: Products?.id,
        user_id: userId,
        plan_price: Products?.special_price,
        orderid: response.razorpay_payment_id,
        duration_id: Products?.durations[0].id ? Products?.durations[0].id : "",
        coupon_id: CouponCode ? CouponCode : "",
        is_dna_pro_included: dnaInclude,
        ...(dnaInclude === "true" && {
          dna_pro_details: {
            plan_id: DnaProducts.id,
            plan_price: DnaProducts.special_price,
          },
        }),
      };
      console.log("body", body);
      try {
        axiosInstance
          // .post(`website/user/buySubcriptionPlan`, body)
          .post(`website/v1/buySubscription`, body)
          .then((val) => {
            // toast("Payment Successful 🤗", { type: "success" });
            const message = val.data.data[0].message;
            setSuccess({ ...Success, payment: true });
            setProgress(false);
            setSteps({
              ...Steps,
              details: "done",
              payment: "done",
              test: "inactive",
            });
            console.log("first", message);
            setPaymentMessage(message);
            // let url = `/contactPage/?userId=${userId}`;
            // let url = "https://www.unlock.fit/";
            // window.location.href = url;
          })
          .catch((err) => {
            setProgress(false);
            const errors = err.response.data;
            toast.error(errors.message);
          });
      } catch (err) {
        console.log(err);
        setProgress(false);
        alert(err.response.message);
      }
    },
    prefill: {
      contact: Data.phone,
      email: Data.email,
    },
  };
  console.log("key", options);
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
  console.log(paymentObject);
};
