/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PublicIcon from "@mui/icons-material/Public";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Popper from "@mui/material/Popper";
import dayjs from "dayjs";
import axios from "axios";
import { displayRazorpay } from "./purchaseUtils.js";
// import { ReactComponent as Happy } from "./assets/happy.svg";
import Happy from "./assets/Congratulations.gif";
import { ReactComponent as Phone } from "./assets/phone.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as Dead } from "./assets/dead-emoji.svg";
import { ReactComponent as Sad } from "./assets/sad-emoji.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as Active } from "./assets/active_stepper.svg";
import { ReactComponent as InActive } from "./assets/inactive_stepper.svg";
import { ReactComponent as Done } from "./assets/success_stepper.svg";
import { ReactComponent as Product } from "./assets/product.svg";
import { ReactComponent as Feature } from "./assets/feature.svg";
import { ReactComponent as Selected } from "./assets/selected.svg";
import { ReactComponent as UnSelected } from "./assets/unselected.svg";
import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { ReactComponent as Agreed } from "./assets/agreement.svg";
import { ReactComponent as NotAgreed } from "./assets/notagreed.svg";
import { ReactComponent as PaymentDone } from "./assets/payment_done.svg";
import { ReactComponent as Coupon } from "./assets/coupon.svg";
import { ReactComponent as Next } from "./assets/next.svg";
import qrcode from "./assets/barcode.svg";
import playStore from "./assets/get_it_on_google.png";
import appStore from "./assets/get_it_on_apple.png";
import logo from "./assets/Asset-11.png";
import "./styles/style.css";
import Month from "./Components/Month";
import CountryCodes from "./Components/CountryCode";
import StateName from "./Components/StateName.js";

const axiosInstance = axios.create({
  baseURL: "https://api.unlock.fit/", //prod
  // baseURL: "https://dev-api.unlock.fit/", //dev
  // baseURL: "https://z5d9jm6x-4002.inc1.devtunnels.ms/", //local
});

// for Email
// let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  borderRadius: "10px",
};

const modalBoxStyleCoupon = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  borderRadius: "10px",
};

const PurchaseContainer = () => {
  const [OpenDownload, setOpenDownload] = useState(false);
  const [Progress, setProgress] = useState(false);
  const [CouponText, setCouponText] = useState("");
  const [PaymentMessage, setPaymentMessage] = useState("");
  const [Products, setProducts] = useState({});
  const [DnaProducts, setDnaProducts] = useState({});
  const [Total, setTotal] = useState("");
  const [CouponCode, setCouponCode] = useState("");
  const [CouponApplied, setCouponApplied] = useState(false);
  const [CouponMessages, setCouponMessages] = useState({});
  const [Terms, setTerms] = useState({});
  const [Privacy, setPrivacy] = useState({});
  const [Success, setSuccess] = useState({
    payment: false,
    addAddress: false,
    success: false,
  });
  const [UserAgreement, setUserAgreement] = useState(false);
  const [Steps, setSteps] = useState({
    details: "current",
    payment: "inactive",
    test: "inactive",
  });
  const [Data, setData] = useState({
    first_name: "",
    last_name: "",
    gender: "male",
    email: "",
    country_code: "+91",
    phone: "",
    user_id: "",
  });
  const [AddressData, setAddressData] = useState({
    user_id: "",
    day: "",
    month: "",
    year: "",
    address_details: {
      line_A: "",
      line_B: "",
      landmark: "",
      city: "",
      pincode: "",
      state: "",
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [OpenDatePicker, setOpenDatePicker] = useState(false);
  const [AvailableSlots, setAvailableSlots] = useState([]);
  const [SlotsError, setSlotsError] = useState("");
  const [Blood, setBlood] = useState("");
  const [SelectedDate, setSelectedDate] = useState(dayjs().add(1, "day"));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const formattedDate = dayjs(SelectedDate).format("DD MMMM YYYY");
  const formattedForSlot = dayjs(SelectedDate).format("YYYY-MM-DD");

  const [GetSlots, setGetSlots] = useState({
    pincode: "",
    date: formattedForSlot,
    user_id: "",
  });

  const [BloodTestSchedule, setBloodTestSchedule] = useState({
    receive_date: formattedForSlot,
    slot: "",
    address_name: "default",
    line_A: "",
    line_B: "",
    landmark: "",
    city: "",
    pincode: "",
    state: "",
    country: "India",
    set_default: true,
    is_pincode_servicable: false,
    is_new_booking: true,
    user_id: "32c48bfe-57a7-460d-8f59-b1ba4b8ae3d1",
  });

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const blackRef = useRef(null);

  // const planId = new URLSearchParams(window.location.search).get("plan_id");
  const planName = new URLSearchParams(window.location.search).get("plan_name");
  const dnaInclude = new URLSearchParams(window.location.search).get(
    "dna_pro_included"
  );
  const durationId = new URLSearchParams(window.location.search).get(
    "duration"
  );

  const getProduct = () => {
    const body = {
      plan_name: planName,
      is_dna_pro_included: dnaInclude,
      duration: durationId,
      coupon_code: CouponText,
    };
    axiosInstance
      .post(`website/v1/product_overview`, body)
      .then((res) => {
        const products = res.data.data[0];
        getSteps(products.selected_product.id);
        setProducts(products.selected_product);
        setDnaProducts(products.dna_pro_plan_details);
        setTotal(products.total_price);
        const productsSpecial = res.data.popup_data;
        setCouponCode(productsSpecial.coupon_id);
        setCouponApplied(productsSpecial.has_popup);
        setCouponMessages(productsSpecial.popup_data);
      })
      .catch((err) => {
        const finalError = err?.response?.data?.message;
        const finalErrorTo = err?.response?.status;
        if (
          finalError === "This duration_id not part of this plan." ||
          finalError === "Please provide the correct plan id." ||
          finalErrorTo === 400
        ) {
          let url = "https://www.unlock.fit/";
          window.location.href = url;
        }
        console.log(finalError);
      });
  };

  const getSteps = (id) => {
    axiosInstance
      .get(`website/v1/steps?plan_id=${Number(id)}`)
      .then((res) => {
        const products = res.data.data[2];
        setBlood(products.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const registerUser = () => {
    axiosInstance
      .post(`website/v1/new/register`, Data)
      .then((res) => {
        const userId = res.data.data[0].user_id;
        setBloodTestSchedule({
          ...BloodTestSchedule,
          user_id: userId,
        });
        checkSub(userId);
        setData({ ...Data, user_id: userId });
        setAddressData({
          ...AddressData,
          user_id: userId,
        });
        setGetSlots({ ...GetSlots, user_id: userId });
      })
      .catch((err) => {
        const errors = err.response.data;
        toast.error(errors.message);
        // console.log(err);
      });
  };

  const checkSub = (userId) => {
    const body = {
      user_id: userId,
      plan_ids: [
        Products.id,
        ...(dnaInclude === "true" ? [DnaProducts.id] : []),
      ],
    };
    axiosInstance
      .post(`website/v1/has_dna_plan`, body)
      .then((res) => {
        const popupdata = res.data.data[0];
        if (popupdata.has_popup) {
          setCouponApplied(popupdata.has_popup);
          setCouponMessages(popupdata.popup_data);
        } else {
          displayRazorpay(
            userId,
            Products,
            Data,
            Number(planName),
            dnaInclude,
            DnaProducts,
            Total,
            setSteps,
            setSuccess,
            Success,
            Steps,
            setPaymentMessage,
            setProgress,
            CouponCode
          );
        }
      })
      .catch((err) => {
        const errors = err.response.data;
        toast.error(errors.message);
        // console.log(err);
      });
  };

  const getTransformedAddressData = () => {
    const { day, month, year, ...rest } = AddressData;
    const date_of_birth = `${year}-${month}-${day}`;
    return {
      ...rest,
      date_of_birth,
    };
  };

  const getSlots = () => {
    axiosInstance
      .post(`website/v1/new/available_slots`, GetSlots)
      .then((res) => {
        const slots = res.data.data;
        setAvailableSlots(slots);
        setBloodTestSchedule({
          ...BloodTestSchedule,
          is_pincode_servicable: res.data.is_pincode_servicable,
        });
      })
      .catch((err) => {
        setAvailableSlots([]);
        const finalError = err.response.data;
        setSlotsError(finalError.message);
        console.log(err);
      });
  };

  const confirmAddress = () => {
    axiosInstance
      .post(`website/v1/add/personal_details`, getTransformedAddressData())
      .then((res) => {
        // const finalData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      AddressData.day &&
      AddressData.month &&
      AddressData.year.length > 3 &&
      AddressData.address_details.line_A &&
      AddressData.address_details.line_B &&
      AddressData.address_details.pincode &&
      AddressData.address_details.city &&
      AddressData.address_details.state
    ) {
      confirmAddress();
    }
  }, [AddressData]);

  const confirmBloodBooking = () => {
    axiosInstance
      .post(`website/v1/new/book_blood_test/false`, BloodTestSchedule)
      .then((res) => {
        // const finalData = res.data.data;
        setSuccess({
          ...Success,
          success: true,
          payment: true,
        });
        setSteps({
          ...Steps,
          test: "done",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      GetSlots.date &&
      GetSlots.pincode.length === 6 &&
      GetSlots.user_id &&
      Blood === "Schedule your Blood Test"
    ) {
      getSlots();
    }
  }, [GetSlots.date, GetSlots.pincode, GetSlots.user_id, Blood]);

  const getTandCondition = () => {
    axiosInstance
      .get(`user/consent/terms_of_use`)
      .then((res) => {
        const TandC = res.data.data[0];
        setTerms(TandC.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrivacyPolicy = () => {
    axiosInstance
      .get(`user/consent/privacy_policy`)
      .then((res) => {
        const policy = res.data.data[0];
        setPrivacy(policy.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showTerms = () => {
    const newWindow = window.open("", "_blank", "width=595,height=842");
    newWindow.document.write(Terms);
    // newWindow.document.close();
  };

  const showPrivacy = () => {
    const newWindow = window.open("", "_blank", "width=595,height=842");
    newWindow.document.write(Privacy);
    // newWindow.document.close();
  };

  useEffect(() => {
    getProduct();
    getTandCondition();
    getPrivacyPolicy();
  }, []);

  const handleResize = () => {
    let headerHeight = 0;
    if (headerRef.current) {
      headerHeight = headerRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }
    let footerHeight = 0;
    if (footerRef.current) {
      footerHeight = footerRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty(
        "--footer-height",
        `${footerHeight + 100}px`
      );
    }
    let blackHeight = 0;
    if (blackRef.current) {
      blackHeight = blackRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty(
        "--black-height",
        `${blackHeight}px`
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const extractDurationNumber = (durationString) => {
    const match = durationString.match(/\d+/);
    return match ? match[0] : null;
  };

  const tomorrow = dayjs().add(1, "day").startOf("day");
  const nextWeek = tomorrow.add(7, "day").endOf("day");

  // Function to disable dates outside the 7-day range from tomorrow
  const shouldDisableDate = (date) => {
    return date.isBefore(tomorrow) || date.isAfter(nextWeek);
  };

  return (
    <>
      <div>
        {/* header */}
        <div>
          <Toaster />
        </div>
        {Progress && (
          <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{ color: "#FFFFFF" }}
              size={80}
              thickness={4}
            />
            <span className="text-[#FFFFFF] mt-2 text-[20px]">
              Please wait while we process your payment
            </span>
          </div>
        )}
        <div
          className="header flex justify-between 2xl:px-12 2xl:py-8 xl:px-10 xl:py-6 px-9 py-5 bg-[#FFFFFF] items-center fixed left-0 top-0  w-[100%] z-[1]"
          ref={headerRef}
        >
          <div className="flex justify-center items-center font-medium 2xl:text-[30px] xl:text-[25px] text-[20px]">
            <img
              className="logo 2xl:h-10 xl:h-8 h-6 mr-1"
              src={logo}
              alt="logo1"
            />
            unlock.fit
          </div>
          <div
            className="flex bg-[#000000] text-[#FFFFFF] 2xl:text-[16px] xl:text-[11px] text-[9px] font-semibold justify-center items-center 2xl:px-8 xl:px-6 2xl:py-3 xl:py-2 px-4 py-2 cursor-pointer"
            onClick={() => setOpenDownload(true)}
          >
            <Phone className="2xl:mr-4 xl:mr-2 mr-1 xl:w-[10px] w-[10px]" />
            Download the app
          </div>
        </div>

        {/* main-content */}
        <div className="main-content">
          <div
            className="w-full md:h-[415px] h-[310px] bg-[#000000] relative"
            ref={blackRef}
          >
            <div className="absolute top-pad flex flex-col text-[#FFFFFF] w-full">
              <div className="stepper flex mt-8 md:mb-16 mb-14 md:text-[16px] text-[10px]">
                <div className="flex-1 flex items-center flex-col">
                  {Steps.details === "current" ? (
                    <Active />
                  ) : Steps.details === "inactive" ? (
                    <InActive />
                  ) : (
                    <Done />
                  )}
                  <div className="mt-2">Enter Personal Details</div>
                </div>
                <div className="flex-1 flex items-center flex-col relative">
                  <div
                    className="absolute"
                    style={{
                      left: "calc(-50% + 20px)",
                      right: "calc(50% + 20px)",
                      top: 13,
                    }}
                  >
                    <span
                      className="block"
                      style={{ border: "1px solid #FFFFFF" }}
                    ></span>
                  </div>
                  {Steps.payment === "current" ? (
                    <Active />
                  ) : Steps.payment === "inactive" ? (
                    <InActive />
                  ) : (
                    <Done />
                  )}
                  <div className="mt-2">Payment</div>
                </div>
                <div className="flex-1 flex items-center flex-col relative">
                  <div
                    className="absolute"
                    style={{
                      left: "calc(-50% + 20px)",
                      right: "calc(50% + 20px)",
                      top: 13,
                    }}
                  >
                    <span
                      className="block"
                      style={{
                        border:
                          Steps.test === "current" || Steps.test === "done"
                            ? "1px solid #FFFFFF"
                            : "1px dashed #4A4A4A",
                      }}
                    ></span>
                  </div>
                  {Steps.test === "current" ? (
                    <Active />
                  ) : Steps.test === "inactive" ? (
                    <InActive />
                  ) : (
                    <Done />
                  )}
                  <div className="mt-2">
                    {Blood === "Schedule your Blood Test"
                      ? "Schedule your Blood Test"
                      : "Complete your Personal Details"}
                  </div>
                </div>
              </div>
              {!Success.payment && (
                <>
                  <div className="forms md:flex justify-around main-height w-[100%] 2xl:px-[200px] xl:px-[100px] p-6">
                    <div className="product flex flex-col items-start flex-1 mt-2">
                      {/* <span className="font-semibold text-[30px] md:mb-7 mb-4">
                        Product Summary
                      </span> */}
                      {dnaInclude === "true" ? (
                        <div
                          className="product-card bg-[#FFFFFF] relative rounded-3xl md:w-[75%] text-[#1C1C1C] flex flex-col items-start md:p-12 p-7"
                          style={{
                            boxShadow: "0px 42px 34px 0px #0C0C0C4B",
                            border: "1px solid #282828",
                          }}
                        >
                          {Success.addAddress && (
                            <div className="flex absolute bottom-5 right-5 justify-center text-[13px] items-center text-[#626262]">
                              Successfully Purchased
                              <Done className="w-[16px] ml-1" />
                            </div>
                          )}
                          {/* {Products?.has_durations && (
                            <div className="duration absolute md:top-11 top-5 right-5 flex flex-col items-center">
                              <div className="md:mb-3 mb-1 md:text-[16px] text-[10px] font-medium">
                                Duration
                              </div>
                              <div
                                className="rounded-xl flex flex-col bg-[#262626] text-[#FFFFFF] p-2 w-[90%]"
                                style={{
                                  border: "0.5px solid #1C1C1C",
                                }}
                              >
                                <span className="md:text-[20px] text-[14px] font-semibold">
                                  {Products?.durations[0]?.duration &&
                                    extractDurationNumber(
                                      Products?.durations[0]?.duration
                                    )}
                                </span>
                                <span className="md:text-[10px] text-[9px] font-semibold">
                                  Month
                                </span>
                              </div>
                            </div>
                          )} */}
                          <div className="text-[25px] text-[#1C1C1C] font-semibold mb-2">
                            Total &nbsp;₹
                            {Total}
                            {Products?.has_durations && (
                              <span className=" ml-4 text-[15px] font-medium">
                                {Products?.durations[0]?.duration &&
                                  extractDurationNumber(
                                    Products?.durations[0]?.duration
                                  )}{" "}
                                Months
                              </span>
                            )}
                          </div>
                          <div className="flex h-fit items-end">
                            <span className="prod_name flex font-bold text-[20px] text-[#FFFFFF] bg-[#393939] text-start">
                              &nbsp;{Products.title}&nbsp;
                            </span>
                            &nbsp;&nbsp;
                            <span className="text-[#1C1C1C] text-[15px] font-semibold">
                              ₹{Products.special_price}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 mb-8 mt-2">
                            {Products?.features?.map((feature, index) => (
                              <div className="flex" key={index}>
                                <div className="mr-2">
                                  <DoneIcon
                                    sx={{ color: "#34B696", fontSize: 15 }}
                                  />
                                </div>
                                <div className="text-justify text-[14px] text-[#333333] font-medium">
                                  {feature}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="flex h-fit items-end">
                            <span className="prod_name flex font-bold text-[20px] text-[#FFFFFF] bg-[#393939] text-start">
                              &nbsp;{DnaProducts.title}&nbsp;
                            </span>
                            &nbsp;&nbsp;
                            <span className="text-[#1C1C1C] text-[15px] font-semibold">
                              ₹{DnaProducts.special_price}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 mt-2">
                            {DnaProducts?.features?.map((feature, index) => (
                              <div className="flex" key={index}>
                                <div className="mr-2">
                                  <DoneIcon
                                    sx={{ color: "#34B696", fontSize: 15 }}
                                  />
                                </div>
                                <div
                                  className="text-justify text-[14px] text-[#333333] font-medium"
                                  dangerouslySetInnerHTML={{
                                    __html: feature,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="product-card bg-[#FFFFFF] relative rounded-3xl md:w-[75%] text-[#1C1C1C] flex flex-col items-start md:p-12 p-7"
                          style={{
                            boxShadow: "0px 42px 34px 0px #0C0C0C4B",
                            border: "1px solid #282828",
                          }}
                        >
                          {!Success.addAddress && (
                            <Product className="absolute top-5 right-5" />
                          )}
                          {Success.addAddress && (
                            <div className="flex absolute bottom-5 right-5 justify-center text-[13px] items-center text-[#626262]">
                              Successfully Purchased
                              <Done className="w-[16px] ml-1" />
                            </div>
                          )}
                          <div className="prod_name font-bold text-[28px] mb-4 text-start">
                            {Products.title}
                          </div>
                          <div className="prod_price font-bold text-[36px] mb-2">
                            ₹ {Products?.special_price}
                          </div>
                          <div
                            className="prod_desc font-medium text-justify mb-8"
                            dangerouslySetInnerHTML={{
                              __html: Products.description,
                            }}
                          />
                          <div className="flex flex-col gap-5 mb-8">
                            {Products?.features?.map((feature, index) => (
                              <div className="flex" key={index}>
                                <div className="mr-3">
                                  <Feature />
                                </div>
                                <div
                                  className="text-justify"
                                  dangerouslySetInnerHTML={{
                                    __html: feature,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          {Products?.has_durations && (
                            <div className="duration">
                              <div className="mb-3 font-medium">Duration</div>
                              <div
                                className="rounded-xl flex flex-col bg-[#262626] text-[#FFFFFF] p-2"
                                style={{
                                  border: "0.5px solid #1C1C1C",
                                }}
                              >
                                <span className="text-2xl font-semibold mb-1">
                                  {Products?.durations[0]?.duration &&
                                    extractDurationNumber(
                                      Products?.durations[0]?.duration
                                    )}
                                </span>
                                <span className="text-xs font-semibold">
                                  Month
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="user-form md:mt-2 mt-10 flex flex-col items-end flex-1">
                      {!Success.addAddress ? (
                        <div
                          className="form-box rounded-xl bg-[#FFFFFF] text-[#000000] md:px-10 px-7 py-8 text-left"
                          style={{
                            boxShadow: "0px 0px 44px 0px #0000001A",
                            border: "1px solid #CDCDCD",
                          }}
                        >
                          <div className="font-semibold md:text-[30px] text-[20px] mb-6">
                            Please Enter the details
                          </div>
                          <div className="form flex flex-col gap-5 text-[16px]">
                            <input
                              placeholder="First Name"
                              type="text"
                              className="outline-none px-5 py-3 rounded-sm"
                              style={{ border: "1px solid #000000" }}
                              value={Data.first_name}
                              onChange={(e) => {
                                setData({
                                  ...Data,
                                  first_name: e.target.value,
                                });
                              }}
                            />
                            <input
                              placeholder="Last Name"
                              type="text"
                              className="outline-none px-5 py-3 rounded-sm"
                              style={{ border: "1px solid #000000" }}
                              value={Data.last_name}
                              onChange={(e) => {
                                setData({ ...Data, last_name: e.target.value });
                              }}
                            />
                            <div className="flex flex-wrap gap-4">
                              <div
                                className="px-5 py-3 rounded-sm flex justify-between items-center cursor-pointer flex-1"
                                style={{ border: "1px solid #000000" }}
                                onClick={() => {
                                  setData({ ...Data, gender: "male" });
                                }}
                              >
                                Male
                                {Data.gender === "male" ? (
                                  <Selected className="ml-3" />
                                ) : (
                                  <UnSelected className="ml-3" />
                                )}
                              </div>
                              <div
                                className="px-5 py-3 rounded-sm flex justify-between items-center cursor-pointer flex-1"
                                style={{ border: "1px solid #000000" }}
                                onClick={() => {
                                  setData({ ...Data, gender: "female" });
                                }}
                              >
                                Female
                                {Data.gender === "female" ? (
                                  <Selected className="ml-3" />
                                ) : (
                                  <UnSelected className="ml-3" />
                                )}
                              </div>
                              <div
                                className="px-5 py-3 rounded-sm flex justify-between items-center cursor-pointer flex-1"
                                style={{ border: "1px solid #000000" }}
                                onClick={() => {
                                  setData({ ...Data, gender: "transgender" });
                                }}
                              >
                                Others
                                {Data.gender === "transgender" ? (
                                  <Selected className="ml-3" />
                                ) : (
                                  <UnSelected className="ml-3" />
                                )}
                              </div>
                            </div>
                            <input
                              placeholder="Email Address"
                              type="email"
                              className="outline-none px-5 py-3 rounded-sm"
                              style={{ border: "1px solid #000000" }}
                              value={Data.email}
                              onChange={(e) => {
                                setData({ ...Data, email: e.target.value });
                              }}
                            />
                            <div className="flex gap-4">
                              <CountryCodes setData={setData} Data={Data} />
                              <input
                                placeholder="Phone Number"
                                type="text"
                                className="outline-none px-5 py-3 rounded-sm w-[70%]"
                                style={{ border: "1px solid #000000" }}
                                value={Data.phone}
                                onChange={(e) => {
                                  if (/^\d*$/.test(e.target.value)) {
                                    setData({ ...Data, phone: e.target.value });
                                  }
                                }}
                              />
                            </div>
                            <div className="flex justify-between items-center">
                              <div
                                className="flex outline-none px-5 py-3 rounded-sm w-full"
                                style={{ border: "1px solid #000000" }}
                              >
                                <input
                                  placeholder="Apply Coupon Code"
                                  type="text"
                                  value={CouponText}
                                  className="outline-none w-full"
                                  onChange={(e) => {
                                    setCouponText(e.target.value);
                                  }}
                                  // onPaste={(e) => {
                                  //   const pastedText = (
                                  //     e.clipboardData || window.clipboardData
                                  //   ).getData("text");
                                  //   setCouponText(pastedText);
                                  //   e.preventDefault();
                                  // }}
                                />
                                <Coupon
                                  className="cursor-pointer"
                                  onClick={async () => {
                                    try {
                                      const text =
                                        await navigator.clipboard.readText();
                                      setCouponText(text);
                                    } catch (err) {
                                      console.error(
                                        "Failed to read clipboard contents: ",
                                        err
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div
                                className="text-[18px] ml-2 cursor-pointer"
                                onClick={() => getProduct()}
                              >
                                Apply
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div
                                className="cursor-pointer mr-2 mt-1"
                                onClick={() => setUserAgreement(!UserAgreement)}
                              >
                                {UserAgreement ? <Agreed /> : <NotAgreed />}
                              </div>
                              <div className="text-[#7E7E7E]">
                                By checking the box, you agree to our{" "}
                                <b
                                  className="text-[#000000] cursor-pointer"
                                  onClick={() => showTerms()}
                                >
                                  Terms & Conditions
                                </b>{" "}
                                and{" "}
                                <b
                                  className="text-[#000000] cursor-pointer"
                                  onClick={() => showPrivacy()}
                                >
                                  Privacy Policy
                                </b>
                              </div>
                            </div>
                            <div
                              className="bg-[#000000] flex justify-center items-center text-[#FFFFFF] font-semibold text-[21px] rounded-lg p-3 cursor-pointer"
                              onClick={() => {
                                if (UserAgreement) {
                                  registerUser();
                                } else {
                                  toast.error(
                                    "Click on the Terms and Conditions and continue your Journey."
                                  );
                                }
                              }}
                            >
                              Proceed to Payment
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="font-semibold md:text-[30px] text-[20px] md:text-[#FFFFFF] text-left text-[#1B1B1B] mb-6">
                            {Blood === "Schedule your Blood Test"
                              ? "Schedule Your Blood Test Sample Pickup"
                              : "Complete your Personal Details"}
                          </div>
                          <div
                            className="form-box rounded-xl bg-[#FFFFFF] text-[#1B1B1B] md:px-10 px-4 py-8 text-left"
                            style={{
                              boxShadow: "0px 0px 44px 0px #0000001A",
                              border: "1px solid #CDCDCD",
                            }}
                          >
                            <div className="flex flex-col gap-5 text-[16px]">
                              <div>Enter Your Date of Birth</div>
                              <div className="flex md:gap-4 gap-2">
                                <input
                                  placeholder="DD"
                                  type="text"
                                  value={AddressData.day}
                                  className="outline-none px-5 py-3 rounded-sm w-[25%] text-center"
                                  style={{ border: "1px solid #000000" }}
                                  onChange={(e) => {
                                    if (e.target.value.length > 2) {
                                      return;
                                    }
                                    if (
                                      e.target.value.length > 2 ||
                                      Number(e.target.value) > 31
                                    ) {
                                      toast.error(
                                        "Please enter a valid day (1-31)"
                                      );
                                      return;
                                    }
                                    if (/^\d*$/.test(e.target.value)) {
                                      setAddressData({
                                        ...AddressData,
                                        day: e.target.value,
                                      });
                                    }
                                  }}
                                />
                                <Month
                                  setAddressData={setAddressData}
                                  AddressData={AddressData}
                                />
                                <input
                                  placeholder="YYYY"
                                  type="text"
                                  value={AddressData.year}
                                  className="outline-none px-5 py-3 rounded-sm md:w-[25%] w-[30%] text-center"
                                  style={{ border: "1px solid #000000" }}
                                  onChange={(e) => {
                                    if (e.target.value.length > 4) {
                                      return;
                                    }
                                    const currentYear =
                                      new Date().getFullYear();
                                    if (
                                      /^\d*$/.test(e.target.value) &&
                                      (e.target.value === "" ||
                                        parseInt(e.target.value) < currentYear)
                                    ) {
                                      setAddressData({
                                        ...AddressData,
                                        year: e.target.value,
                                      });
                                    } else {
                                      toast.error(
                                        `Enter a year less than ${currentYear}`
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div>Enter Your Address</div>
                              <input
                                placeholder="House No/ Flat No."
                                type="text"
                                value={AddressData.address_details.line_A}
                                className="outline-none px-5 py-3 rounded-sm"
                                style={{ border: "1px solid #000000" }}
                                onChange={(e) => {
                                  setBloodTestSchedule({
                                    ...BloodTestSchedule,
                                    line_A: e.target.value,
                                  });
                                  setAddressData((prevState) => ({
                                    ...prevState,
                                    address_details: {
                                      ...prevState.address_details,
                                      line_A: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <input
                                placeholder="Street"
                                type="text"
                                value={AddressData.address_details.line_B}
                                className="outline-none px-5 py-3 rounded-sm"
                                style={{ border: "1px solid #000000" }}
                                onChange={(e) => {
                                  setBloodTestSchedule({
                                    ...BloodTestSchedule,
                                    line_B: e.target.value,
                                  });
                                  setAddressData((prevState) => ({
                                    ...prevState,
                                    address_details: {
                                      ...prevState.address_details,
                                      line_B: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <input
                                placeholder="Landmark"
                                type="text"
                                value={AddressData.address_details.landmark}
                                className="outline-none px-5 py-3 rounded-sm"
                                style={{ border: "1px solid #000000" }}
                                onChange={(e) => {
                                  setBloodTestSchedule({
                                    ...BloodTestSchedule,
                                    landmark: e.target.value,
                                  });
                                  setAddressData((prevState) => ({
                                    ...prevState,
                                    address_details: {
                                      ...prevState.address_details,
                                      landmark: e.target.value,
                                    },
                                  }));
                                }}
                              />{" "}
                              <input
                                placeholder="Pin Code"
                                type="text"
                                value={GetSlots.pincode}
                                className="outline-none px-5 py-3 rounded-sm"
                                style={{ border: "1px solid #000000" }}
                                onChange={(e) => {
                                  if (e.target.value.length > 6) {
                                    return;
                                  }
                                  if (/^\d*$/.test(e.target.value)) {
                                    setGetSlots({
                                      ...GetSlots,
                                      pincode: e.target.value,
                                    });
                                    setBloodTestSchedule({
                                      ...BloodTestSchedule,
                                      pincode: e.target.value,
                                    });
                                    setSlotsError("");
                                    setAddressData((prevState) => ({
                                      ...prevState,
                                      address_details: {
                                        ...prevState.address_details,
                                        pincode: e.target.value,
                                      },
                                    }));
                                  }
                                }}
                              />
                              <input
                                placeholder="City Name"
                                type="text"
                                value={AddressData.address_details.city}
                                className="outline-none px-5 py-3 rounded-sm"
                                style={{ border: "1px solid #000000" }}
                                onChange={(e) => {
                                  setBloodTestSchedule({
                                    ...BloodTestSchedule,
                                    city: e.target.value,
                                  });
                                  setAddressData((prevState) => ({
                                    ...prevState,
                                    address_details: {
                                      ...prevState.address_details,
                                      city: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <StateName
                                setAddressData={setAddressData}
                                setBloodTestSchedule={setBloodTestSchedule}
                                BloodTestSchedule={BloodTestSchedule}
                              />
                              {Blood === "Schedule your Blood Test" ? (
                                <div className="flex flex-col gap-5 text-[16px]">
                                  <div>Select Date</div>
                                  <div
                                    className="outline-none px-5 py-3 rounded-sm cursor-pointer flex justify-between"
                                    style={{ border: "1px solid #000000" }}
                                    aria-describedby={id}
                                    onClick={(event) => {
                                      setOpenDatePicker(true);
                                      setAnchorEl(
                                        anchorEl ? null : event.currentTarget
                                      );
                                    }}
                                  >
                                    {formattedDate}
                                    <Calendar />
                                  </div>
                                  <Popper
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={() => {
                                      setAnchorEl(false);
                                      setOpenDatePicker(false);
                                    }}
                                    sx={{
                                      bgcolor: "#F5F5F5",
                                      borderRadius: "16px",
                                      minWidth: "334px",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DateCalendar
                                        views={["day"]}
                                        open={OpenDatePicker}
                                        onClose={() => setOpenDatePicker(false)}
                                        value={SelectedDate}
                                        shouldDisableDate={shouldDisableDate}
                                        onChange={(event) => {
                                          setAnchorEl(null);
                                          setSelectedDate(event);
                                          const formattedDate =
                                            dayjs(event).format("YYYY-MM-DD");
                                          setGetSlots({
                                            ...GetSlots,
                                            date: formattedDate,
                                          });
                                          setBloodTestSchedule({
                                            ...BloodTestSchedule,
                                            receive_date: formattedDate,
                                          });
                                        }}
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            display: "none",
                                          },
                                          "& .MuiPickersCalendarHeader-root":
                                            {},
                                          "& .MuiPickersArrowSwitcher-button": {
                                            border: 0,
                                            backgroundColor: "#FFFFFF",
                                          },
                                          "& .MuiPickersDay-root": {
                                            color: "#1F1F1F",
                                            borderRadius: "50%",
                                            backgroundColor: "#FFFFFF",
                                            fontSize: "16px",
                                            margin: "3px",
                                            height: 43,
                                            width: 43,
                                          },
                                          "& .Mui-selected": {
                                            color: "#FFF !important",
                                            backgroundColor:
                                              "#000000 !important",
                                          },
                                          "& .MuiPickersCalendarHeader-labelContainer":
                                            {
                                              fontSize: "22px",
                                              fontFamily: "poppins",
                                            },
                                          "& .MuiDayCalendar-weekDayLabel": {
                                            fontSize: "16px",
                                            height: 43,
                                            width: 43,
                                          },
                                          "& .MuiIconButton-edgeEnd": {
                                            marginRight: "2px",
                                          },
                                          "& .MuiDayCalendar-slideTransition": {
                                            minHeight: 260,
                                          },
                                          minWidth: "365px",
                                          height: "370px",
                                          maxHeight: "365px",
                                        }}
                                        disablePast
                                      />
                                    </LocalizationProvider>
                                  </Popper>
                                  {(AvailableSlots.length > 0 ||
                                    SlotsError) && (
                                    <div className="gap-5 flex flex-col">
                                      <div className="flex items-center">
                                        Pick Your Time Slot &nbsp;&nbsp;&nbsp;{" "}
                                        <span className="text-[#7F7F7F] text-[10px]">
                                          <PublicIcon
                                            sx={{ fontSize: "15px" }}
                                          />
                                          &nbsp; India Standard Time (IST) is
                                          5:30
                                        </span>
                                      </div>
                                      <div className="flex flex-wrap justify-between gap-2 w-full">
                                        {AvailableSlots.map((slots) => (
                                          <div
                                            className={`${
                                              BloodTestSchedule.slot ===
                                              slots.slot
                                                ? "bg-[#161616] text-[#FFFFFF]"
                                                : "bg-[#FFFFFF] text-[#292D35]"
                                            } rounded p-2 md:w-[30%] w-[45%] text-[12px] text-center cursor-pointer`}
                                            style={{
                                              border: "0.5px solid #727272",
                                            }}
                                            onClick={() => {
                                              setBloodTestSchedule({
                                                ...BloodTestSchedule,
                                                slot: slots.slot,
                                              });
                                            }}
                                          >
                                            {slots.slot}
                                          </div>
                                        ))}
                                        {AvailableSlots && SlotsError}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                              <div
                                className="bg-[#000000] flex justify-center items-center text-[#FFFFFF] font-semibold text-[21px] rounded-lg p-3 cursor-pointer"
                                onClick={() => {
                                  if (
                                    !AddressData.day ||
                                    !AddressData.month ||
                                    AddressData.year.length < 4 ||
                                    !AddressData.address_details.line_A ||
                                    !AddressData.address_details.line_B ||
                                    AddressData.address_details.pincode
                                      .length !== 6 ||
                                    !AddressData.address_details.city ||
                                    !AddressData.address_details.state
                                  ) {
                                    toast.error("Fill all the Details.");
                                    return;
                                  }
                                  if (
                                    Blood === "Schedule your Blood Test" &&
                                    AvailableSlots &&
                                    !BloodTestSchedule.slot
                                  ) {
                                    toast.error("Select a time slot.");
                                    return;
                                  }
                                  if (Blood === "Schedule your Blood Test") {
                                    confirmBloodBooking();
                                  } else {
                                    setSuccess({
                                      ...Success,
                                      success: true,
                                      payment: true,
                                    });
                                  }
                                }}
                              >
                                {Blood === "Schedule your Blood Test"
                                  ? "Schedule Now"
                                  : "Finish"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* footer */}
                  <div
                    ref={footerRef}
                    className="footer flex justify-center items-center bg-[#FFFFFF] w-[100%] z-[1]"
                    style={{ boxShadow: "0px -12px 41px 0px #0000000F" }}
                  >
                    <div className="flex justify-center items-center text-[#B9B9B9] font-medium 2xl:text-[25px] xl:text-[20px] p-3">
                      <Logo className="mr-2 xl:w-[30px]" />
                      <div>unlock.fit</div>
                    </div>
                    <span className="text-[#B9B9B9] 2xl:text-[16px] xl:text-[11px] text-[10px] ml-5">
                      @unlock.fit. All rights reserved.
                    </span>
                  </div>
                </>
              )}
            </div>
            {Success.payment && (
              <div className="payment-success absolute top-black text-[#919191] w-full">
                {!Success.success ? (
                  <>
                    <div className="flex justify-center flex-col items-center gap-8 mt-10 pay md:px-[25%] px-[5%]">
                      <PaymentDone />
                      <span className="text-[#000000] text-[30px] font-semibold">
                        Payment Successful
                      </span>
                      <span
                        className="md:text-[20px] text-[15px]"
                        dangerouslySetInnerHTML={{ __html: PaymentMessage }}
                      />
                      <div
                        className="bg-[#000000] flex justify-center items-center text-[#FFFFFF] font-semibold text-[21px] rounded-lg p-3 md:w-[70%] w-[100%] cursor-pointer"
                        onClick={() => {
                          setSuccess({
                            ...Success,
                            addAddress: true,
                            payment: false,
                          });
                          setSteps({
                            ...Steps,
                            test: "current",
                          });
                        }}
                      >
                        Next <Next className="ml-3" />
                      </div>
                    </div>
                    {/* footer */}
                    <div
                      ref={footerRef}
                      className="footer flex justify-center items-center bg-[#FFFFFF] w-[100%] z-[1]"
                      style={{ boxShadow: "0px -12px 41px 0px #0000000F" }}
                    >
                      <div className="flex justify-center items-center text-[#B9B9B9] font-medium 2xl:text-[25px] xl:text-[20px] p-3">
                        <Logo className="mr-2 xl:w-[30px]" />
                        <div>unlock.fit</div>
                      </div>
                      <span className="text-[#B9B9B9] 2xl:text-[16px] xl:text-[11px] text-[10px] ml-5">
                        @unlock.fit. All rights reserved.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center flex-col items-center gap-6 mt-10 text-[#000000] pay md:px-[25%] px-[5%]">
                      <img
                        className="qr md:mt-12 md:mb-2 mb-1 md:w-[35%] w-[50%]"
                        src={qrcode}
                        alt="qr"
                      />
                      <div className="flex">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.unlock.fit"
                          target="_blank"
                          className="mr-2"
                          rel="noopener noreferrer"
                        >
                          <img className="qr " src={playStore} alt="qr" />
                        </a>

                        <a
                          href="https://apps.apple.com/in/app/unlock-fit-dna-based-wellness/id1573454926"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="qr" src={appStore} alt="qr" />
                        </a>
                      </div>
                      <span className="font-semibold md:text-[40px] text-[20px]">
                        Thanks for Choosing Unlock.fit
                      </span>
                      <span className="text-[16px]">
                        {Products[0]?.title === "DNA Pro®" &&
                          "Please download Unlock.fit app to view your DNA Report by scanning this QR Code."}
                        {Products[0]?.title === "Nutri Pro" &&
                          "Your personalized nutrition plan wil be available on the unlock.fit app. Download the app now by scanning this QR code."}
                        {Products[0]?.title !== "Nutri Pro" &&
                          Products[0]?.title !== "DNA Pro®" &&
                          "To get your blood report download unlock.fit app by scan this QR"}
                      </span>
                      <div
                        className="bg-[#000000] flex justify-center items-center text-[#FFFFFF] font-semibold text-[19px] rounded-lg px-20 py-3 cursor-pointer"
                        onClick={() => {
                          let url = "https://www.unlock.fit/";
                          window.location.href = url;
                        }}
                      >
                        Done
                      </div>
                    </div>
                    {/* footer */}
                    <div
                      ref={footerRef}
                      className="footer flex justify-center items-center bg-[#FFFFFF] w-[100%] z-[1]"
                      style={{ boxShadow: "0px -12px 41px 0px #0000000F" }}
                    >
                      <div className="flex justify-center items-center text-[#B9B9B9] font-medium 2xl:text-[25px] xl:text-[20px] p-3">
                        <Logo className="mr-2 xl:w-[30px]" />
                        <div>unlock.fit</div>
                      </div>
                      <span className="text-[#B9B9B9] 2xl:text-[16px] xl:text-[11px] text-[10px] ml-5">
                        @unlock.fit. All rights reserved.
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* download-the-app-modal */}
        <Modal
          open={OpenDownload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBoxStyle}>
            <div className="flex w-full flex-col items-center relative md:px-20 px-4 pb-4">
              <Close
                className="absolute right-0 top-0 m-2 cursor-pointer"
                onClick={() => setOpenDownload(false)}
              />
              <img
                className="qr mt-12 mb-2 md:w-[60%] w-[70%]"
                src={qrcode}
                alt="qr"
              />
              <div className="flex mb-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.unlock.fit"
                  target="_blank"
                  className="mr-2"
                  rel="noopener noreferrer"
                >
                  <img className="qr " src={playStore} alt="qr" />
                </a>

                <a
                  href="https://apps.apple.com/in/app/unlock-fit-dna-based-wellness/id1573454926"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="qr" src={appStore} alt="qr" />
                </a>
              </div>
              <span className="font-semibold md:text-[30px] text-[20px] md:leading-[50px] leading-[20px]">
                The smart way to eat.
              </span>
              <span className="font-semibold md:text-[30px] text-[20px] md:leading-[50px] leading-[20px]">
                Download our app.
              </span>
            </div>
          </Box>
        </Modal>

        {/* coupon applied */}
        <Modal
          open={CouponApplied}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBoxStyleCoupon} className="md:w-[30%] w-[70%]">
            <div className="flex flex-col items-center p-4 gap-4">
              {CouponMessages?.title === "Hurray!" ? (
                // <Happy />
                <img src={Happy} />
              ) : CouponMessages?.title === "Oops!" ? (
                <Sad />
              ) : (
                <Dead />
              )}
              {CouponMessages?.title === "Conflict!" && (
                <CloseIcon
                  className="absolute right-1 top-1 cursor-pointer"
                  onClick={() => setCouponApplied(false)}
                />
              )}
              {CouponMessages?.title !== "Hurray!" && (
                <span className="font-semibold text-[30px]">
                  {CouponMessages?.title}
                </span>
              )}
              <span className="font-semibold text-center text-[20px]">
                {CouponMessages?.message}
              </span>
              {CouponMessages?.price_update?.strike_price && (
                <div className="text-[27px]">
                  <span className="original line-through mr-3 font-light">
                    ₹ {CouponMessages?.price_update?.strike_price}
                  </span>
                  <span className="special font-bold">
                    ₹ {CouponMessages?.price_update?.discount_price}
                  </span>
                </div>
              )}
              {CouponMessages?.title !== "Conflict!" && (
                <div
                  className="bg-[#000000] flex justify-center items-center text-[#FFFFFF] mt-5 font-semibold text-[15px] rounded-lg px-16 py-3 cursor-pointer"
                  onClick={() => setCouponApplied(false)}
                >
                  {CouponMessages?.title === "Hurray!"
                    ? "Claim Now"
                    : CouponMessages?.title === "Oops!"
                    ? "Try Again"
                    : "Try New"}{" "}
                  <Next className="ml-2 w-4" />
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default PurchaseContainer;
